import React, { useEffect, useState } from "react";
import { HTTP_STATUS_CODES } from "../../api/API";
import { Auth } from "../../api/Auth";
import { useFormFieldOnChange } from "../../utils/FormHooks";

import "./SignUpPage.css";

const SignUpPage = () => {
  const [succeeded, setSucceeded] = useState(false);
  const [failed, setFailed] = useState(false);
  const [pwDontMatch, setPwDontMatch] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [nameInput, setNameInput, onNameChange] = useFormFieldOnChange("");
  const [emailInput, setEmailInput, onEmailChange] = useFormFieldOnChange("");
  const [passwordInput, setPasswordInput, onPasswordChange] =
    useFormFieldOnChange("");
  const [confirmPwInput, setConfirmPwInput, onConfirmPwChange] =
    useFormFieldOnChange("");

  const onNameInputChange = (e) => {
    onNameChange(e);
    hideStatusMessages();
  };

  const onEmailInputChange = (e) => {
    onEmailChange(e);
    hideStatusMessages();
  };

  const onPasswordInputChange = (e) => {
    onPasswordChange(e);
    hideStatusMessages();
  };

  const onConfirmPwInputChange = (e) => {
    onConfirmPwChange(e);
    hideStatusMessages();
  };

  useEffect(() => {
    if (
      passwordInput.trim().length == "" ||
      confirmPwInput.trim().length == ""
    ) {
      setPwDontMatch(false);
      return;
    }
    setPwDontMatch(passwordInput !== confirmPwInput);
  }, [passwordInput, confirmPwInput]);

  const submitForm = async (e) => {
    e.preventDefault();

    if (processing) {
      return;
    }
    if (
      nameInput.trim().length == "" ||
      emailInput.trim().length == "" ||
      passwordInput.trim().length == "" ||
      confirmPwInput.trim().length == ""
    ) {
      return;
    }

    if (passwordInput !== confirmPwInput) {
      setPwDontMatch(true);
      return;
    }

    hideStatusMessages();

    setProcessing(true);
    const reqRes = await Auth().PreRegister(
      nameInput,
      emailInput,
      passwordInput
    );
    setProcessing(false);

    if (reqRes == false || reqRes.status != HTTP_STATUS_CODES.OK) {
      setFailed(true);
      return;
    }

    resetForm();
    setSucceeded(true);
  };

  const hideStatusMessages = () => {
    setProcessing(false);
    setFailed(false);
    setSucceeded(false);
    setPwDontMatch(false);
  };

  const resetForm = () => {
    setNameInput("");
    setEmailInput("");
    setPasswordInput("");
    setConfirmPwInput("");
    hideStatusMessages();
  };

  const continueAfterRegistration = (e) => {
    e.preventDefault();
    window.location.href = "https://bringbackaustralia.app/";
  };

  const accountCreated = () => {
    return (
      <>
        <p style={{ fontWeight: "normal" }}>
          Check your email and follow the instructions to verify your account.
        </p>
        <div className="FormButtonContainer">
          <button onClick={continueAfterRegistration}>Continue</button>
        </div>
      </>
    );
  };

  const formContent = () => {
    return (
      <>
        <div className="FormFieldRow">
          <label>Name</label>
          <input
            type="text"
            placeholder="Enter your name..."
            value={nameInput}
            onChange={onNameInputChange}
          />
        </div>
        <div className="FormFieldRow">
          <label>Email</label>
          <input
            type="text"
            placeholder="Enter your email..."
            value={emailInput}
            onChange={onEmailInputChange}
          />
        </div>
        <div className="FormFieldRow">
          <label>Password</label>
          <input
            type="password"
            placeholder="Enter your password..."
            value={passwordInput}
            onChange={onPasswordInputChange}
          />
        </div>
        <div className="FormFieldRow">
          <label>Confirm your password</label>
          <input
            type="password"
            placeholder="Confirm your password..."
            value={confirmPwInput}
            onChange={onConfirmPwInputChange}
          />
        </div>
        {failed && (
          <div className="FormFieldRow Error">
            An error has occurred during the registration process.
          </div>
        )}
        {pwDontMatch && (
          <div className="FormFieldRow Error">
            You've entered different passwords!
          </div>
        )}
        {succeeded && (
          <div className="FormFieldRow Success">
            Congratulations, your account has been created.
            <br />
            Please, read the instructions we've sent to your email on how to
            activate your account.
          </div>
        )}
        <div className="FormButtonContainer">
          <button disabled={processing} onClick={submitForm}>
            Confirm
          </button>
          <button
            disabled={processing}
            onClick={(e) => {
              e.preventDefault();
              resetForm();
            }}
          >
            Reset
          </button>
        </div>
      </>
    );
  };

  return (
    <div className="SignUpPageContainer">
      <form>
        <img
          style={{ width: "200px", marginBottom: "1rem", alignSelf: "center" }}
          src="BBA-Logo-TBl-S.png"
          alt=""
        />
        <p>{!succeeded ? "Create your acount" : "Account created"}</p>
        {!succeeded ? formContent() : accountCreated()}
      </form>
    </div>
  );
};

export default SignUpPage;
