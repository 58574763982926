import { format } from 'date-fns/esm';
import React, { useContext } from 'react';
import { DashboardPopUpContext } from '../../dashboard-components/context/DashboardPopUpContext';
import ApprovalModal from './ApprovalModal';
import { RowItemStyle } from './RowItemStyle';

const APPROVAL_STATUS_NEW_ENTRY = 1000088;
const APPROVAL_STATUS_APPROVED = 1000090;
const APPROVAL_STATUS_REJECTED = 1000091;
const APPROVAL_STATUS_MORE_INFO_REQUESTED = 1000089;

const RowItem = ({ account, refreshCallback }) => {

  const { showPopUp } = useContext(DashboardPopUpContext);

  const onClickRow = () => {
    if (account.Status == APPROVAL_STATUS_NEW_ENTRY) {
      showPopUp(<ApprovalModal account={account} refreshCallback={refreshCallback} />)
    }
  };

  const formattedDate = format(new Date(account.LastUpdate), 'dd/MM/yyyy HH:mm:ss')

  return (
    <RowItemStyle onClick={onClickRow}>
      <span>{account.CompanyName}</span>
      <span>{account.user.NameFirst} {account.user.NameSurname}</span>
      {account.user.Position?.trim().length > 0 && (
        <span>{account.user.Position}</span>
      )}
      <span>{formattedDate}</span>
    </RowItemStyle>
  );
};

export default RowItem;