import React from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/AuthContext";
import DashboardContainer from "../../dashboard-components/DashboardContainer";
import "./DashboardHome.scss";

const DashboardHome = (props) => {
  const userAuth = useAuthContext();
  const user = userAuth.user;

  const navigate = useNavigate();

  const getStarted = (e) => {
    e.preventDefault();

    if (userAuth.isUserSupplier()) {
      navigate("/ProductsPage");
    } else if (userAuth.isUserRetailer()) {
      navigate("/DashboardHome");
    } else if (userAuth.isUserServiceProvider()) {
      navigate("/DashboardHome");
    } else {
      navigate("/GetStarted/AccountContactDetails");
    }
  };

  const isUserAdmin = user.isAdmin || user.isSuperUser || user.isEmployee;

  if (
    !isUserAdmin &&
    user.hasSignedUp &&
    !user.isSupplier &&
    !user.isRetailer &&
    !user.isServiceProvider
  ) {
    return (
      <Navigate
        to={"/GetStarted/AccountTypeSelection"}
        state={{ referer: props.location }}
      />
    );
  }

  if (isUserAdmin) {
    return (
      <DashboardContainer title={"Welcome"}>
        <div className="welcome-container">
          <div className="message">
            <span>Hi {user.NameFirst},</span>
            {user.isAdmin ||
              (user.isSuperUser && (
                <span>
                  Welcome to Bring Back Australia Dashboard for Admins
                </span>
              ))}
            {user.isEmployee && (
              <span>
                Welcome to Bring Back Australia Dashboard for Employees
              </span>
            )}
          </div>
        </div>
      </DashboardContainer>
    );
  }

  return (
    <DashboardContainer title={"Welcome"}>
      <div className="welcome-container">
        <div className="message">
          <span>Hi {user.NameFirst},</span>
          {user.isApprovalBeingProcessed && (
            <>
              <span>Your application is being processed.</span>
              <span>We will let know via email once it has been assessed.</span>
            </>
          )}
          {!user.isApprovalBeingProcessed && (
            <>
              <span>Welcome to Bring Back Australia</span>
              <span className="get-started">To get started</span>
              <span className="click-here">
                click
                <a href="/" onClick={getStarted}>
                  {" "}
                  here
                </a>
                .
              </span>
            </>
          )}
        </div>
      </div>
    </DashboardContainer>
  );
};

export default DashboardHome;
