import React from "react";
import { Link } from "react-router-dom";
import SideMenuGPC from "./SideMenuGPC";
import SideMenuItem from "./SideMenuItem";

const SideMenuAdmin = () => {
  return (
    <>
      {/* this is the official side menu  */}
      <SideMenuItem>
        <Link to="/">Main</Link>
        <SideMenuItem>
          <Link to="/GVDEditor">GVD Editor</Link>
          <Link to="/ValidateGVD">Validate GVD</Link>
          <Link to="/ProductImport">Product Import</Link>
          <Link to="/ProductScan">Product Scan</Link>
          <Link to="/SignupApproval">Signup Approval</Link>
          <Link to="/Brands">Brands</Link>
          <Link to="/">Overrides</Link>
          <Link to="/">Analytics</Link>
        </SideMenuItem>
      </SideMenuItem>
      <SideMenuGPC />
    </>
  );
};

export default SideMenuAdmin;
