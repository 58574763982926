import React, { useContext, useEffect, useState } from 'react';
import DashboardPopUp from '../../dashboard-components/DashboardPopUp';
import { useFormFieldOnChange } from '../../utils/FormHooks';
import { Button } from './Style';

import styled from 'styled-components'
import { addCheck, approve, getSignUpDetail, listChecks, reject } from '../../api/SignupApproval';
import { HTTP_STATUS_CODES } from '../../api/API';
import { format } from 'date-fns';
import { DashboardPopUpContext } from '../../dashboard-components/context/DashboardPopUpContext';

const Body = styled.div`
  margin-bottom: 15px;

  &:last-child {
    margin-bottom: 5px;
  }
`;

const Footer = styled.div`
  & > button:not(:last-child) {
    margin-right: 5px;
  }
`;

const DetailsHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 10px;
`;

const ChecksHeader = styled.div`
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid black;
  padding-bottom: 5px;
  margin-bottom: 10px;

  & > span {
    flex-grow: 1;
    text-align: right;
  }
`;

const TextArea = styled.textarea`
  border: 1px solid black;
  outline: none !important;
  resize: none;
  background-color: #e2e2e2;
  padding: 10px;
  width: 100%;
`;

const ReasonsContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 5px;

  & > div {
    font-size: 15px;
  }
`;

const ChecksContainer = styled.div`
  display: flex;
  flex-direction: column;

  & > select {
    padding: 5px;
  }

  & > *:not(:last-child) {
    margin-bottom: 5px;
  }
`;

export const InputText = styled.input`
  padding: 5px;
  outline: none;
  border: none;
  border: 1px solid #707070;
  width: 100%;
`

export const ChecksHistory = styled.div`
  font-size: 14px;
  padding-bottom: 5px;
  border-bottom: 1px solid black;
  margin-bottom: 5px;
`

const ApprovalModal = ({ account, refreshCallback }) => {

  const { hidePopUp } = useContext(DashboardPopUpContext);

  const [loading, setLoading] = useState(false);

  const [signupDetails, setSignupDetails] = useState(account);

  const [selectedCheck, setSelectedCheck] = useFormFieldOnChange(null);
  const [checkTitle, setCheckTitle, onChangeCheckTitle] = useFormFieldOnChange('');
  const [checkText, setCheckText, onChangeCheckText] = useFormFieldOnChange('');

  const [errorMsg, setErrorMsg] = useState(null);

  const onChangeSelectedCheck = (e) => {
    const value = e.target.value;
    setSelectedCheck(value);

    const checkOption = signupDetails?.checks?.find(check => check.ID == value);

    setSelectedCheck(checkOption);

    if (checkOption.Order == 9) {
      setCheckTitle('');
    }
  }

  const Actions = () => {
    return (
      <div>
        <span><i><small>Assigned to: </small><i /></i></span>
        <select value={signupDetails?.userassignedto?.ID} style={{ marginRight: '10px' }}>
          {signupDetails?.userslist?.map((signup, index) => (
            <option key={index} value={signup.ID}>{signup.NameFirst} {signup.NameSurname}</option>
          ))}
        </select>
      </div>
    );
  };

  useEffect(() => {
    fetchSignUpDetails();
  }, []);

  const fetchSignUpDetails = async () => {
    setLoading(true);
    const res = await getSignUpDetail(account.ID);
    setLoading(false)
    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setSignupDetails(res.data);
  };

  const addNewCheck = async () => {
    setErrorMsg(null);

    if (selectedCheck === null) {
      setErrorMsg('Please, select an option from the list of checks.');
      return;
    }

    if (selectedCheck.Order == 9 && !checkTitle.trim().length) {
      setErrorMsg('Please, inform a title for the check.');
      return;
    }

    if (!checkText.trim().length) {
      setErrorMsg('Please, inform the details of the check.');
      return;
    }

    setLoading(true);
    const res = await addCheck(account.ID, selectedCheck.ID, checkTitle, checkText);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.CREATED) {
      return;
    }

    setCheckTitle('');
    setCheckText('');
    fetchSignupHistory();
  };

  const fetchSignupHistory = async () => {
    setLoading(true);
    const res = await listChecks(account.ID);
    setLoading(false);

    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setSignupDetails(function (currentState) {
      return {
        ...currentState,
        signuphistory: res.data
      }
    });
  };

  const approveClick = async () => {
    setLoading(true);

    const res = await approve(account.ID);
    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setLoading(false);
    hidePopUp();
    refreshCallback();

  };

  const rejectClick = async () => {
    setLoading(true);

    const res = await reject(account.ID);
    if (res.status !== HTTP_STATUS_CODES.OK) return;

    setLoading(false);
    hidePopUp();
    refreshCallback();
  };

  return (
    <DashboardPopUp processing={loading} header={signupDetails.company.CompanyName} actions={<Actions />}>
      <Body>
        <DetailsHeader>
          <b>Details</b>
        </DetailsHeader>
        <ReasonsContainer>
          <div style={{
            gridColumnStart: 1,
            gridColumnEnd: 3
          }}>
            Tax Number: {signupDetails.company.TaxNumber ?? 'NOT DEFINED'}
          </div>
          <div>{signupDetails.company.AddressStreet1 ?? 'NOT DEFINED'}</div>
          <div>{signupDetails.company.AddressCity ?? 'NOT DEFINED'}</div>
          <div>{signupDetails.company.AddressState ?? 'NOT DEFINED'}</div>
          <div>{signupDetails.company.AddressPostCode ?? 'NOT DEFINED'}</div>
          <div style={{
            gridColumnStart: 1,
            gridColumnEnd: 3
          }}>
            {signupDetails.company.countryaddress?.CountryName ?? 'NOT DEFINED'}
          </div>
        </ReasonsContainer>
      </Body>
      <Body>
        <DetailsHeader>
          <b>User Details</b>
        </DetailsHeader>
        <ReasonsContainer>
          <div style={{
            gridColumnStart: 1,
            gridColumnEnd: 3
          }}>{signupDetails.user.NameFirst} {signupDetails.user.NameSurname}</div>
          <div>{signupDetails.user.PhoneMobile}</div>
          <div>{signupDetails.user.Email}</div>
        </ReasonsContainer>
      </Body>
      <Body>
        <ChecksHeader>
          <b>Checks</b>
          <span></span>
        </ChecksHeader>
        <ChecksContainer>
          <select onChange={onChangeSelectedCheck} value={selectedCheck?.ID}>
            {signupDetails?.checks?.map((check, key) => (
              <option key={key} value={check.ID}>{check.SelectionName}</option>
            ))}
          </select>
          <InputText placeholder="Title" style={{ display: selectedCheck?.Order == 9 ? 'block' : 'none' }} onChange={onChangeCheckTitle} value={checkTitle} type="text" />
          <TextArea placeholder="Description" onChange={onChangeCheckText} value={checkText} rows={5} cols={13} />
          <Button onClick={addNewCheck}>Save</Button>
          {errorMsg !== null && (
            <small><span style={{ color: 'red', fontWeight: 'bold' }}>{errorMsg}</span></small>
          )}
        </ChecksContainer>
      </Body>
      <Body>
        <DetailsHeader>
          <b>History</b>
        </DetailsHeader>
        {signupDetails?.signuphistory?.map((history, index) => (
          <ChecksHistory>
            <b>ID:</b> {history.ID}
            <span> - {format(new Date(history.DateCreated), 'dd/MM/yyyy HH:mm:ss')}</span>
            <br />
            <b>Check:</b> {history.signupcheck.SelectionName}
            <br />
            <b>Title:</b> {history.SignupCheckTitle ?? 'NOT DEFINED'}
            <br />
            <b>User: </b>{history.user.NameFirst} {history.user.NameSurname}
            <br />
            <b>Description: </b>{history.SignupCheckText}
          </ChecksHistory>
        ))}
        {!signupDetails?.signuphistory?.length && (
          <i><b>No records</b></i>
        )}
      </Body>
      <Footer>
        {signupDetails?.readonly === false && (
          <>
            <Button onClick={approveClick}>Approve</Button>
            <Button onClick={rejectClick}>Reject</Button>
            <Button onClick={hidePopUp}>Close</Button>
          </>
        )}
        {signupDetails?.readonly === true && (
          <small>
            <i>
              <b style={{ color: 'red' }}>
                This record has been assigned to&nbsp;
                {signupDetails?.userassignedto?.NameFirst}&nbsp;
                {signupDetails?.userassignedto?.NameSurname}
              </b>
            </i>
          </small>
        )}
      </Footer>
    </DashboardPopUp>
  );
};

export default ApprovalModal;